import React, { useState, useRef } from "react";
import EazyHeader from "../../../components/Header";
import Layout from "../../../components/Layout";
import { Helmet } from "react-helmet";
import "../../../../src/commonStyle/style.css";
import "./ondc.css";
import ThumbnailImage from "../../../images/ondc.png";

import GirlsShopping from "../../../images/girls-hopping.webp";
import amblem from "../../../images/amblem.webp";
import city from "../../../images/Citys.webp";
import sku from "../../../images/sku.webp";
import sellers from "../../../images/sellers.webp";

import zoppingMoney from "../../../images/zopping-money.webp";
import zoppingSupport from "../../../images/Zopping-support.webp";
import zoppingApp from "../../../images/Zopping-app.webp";
import zoppingONDC from "../../../images/Zopping-ondc.webp";

import CustomersInsights from "../../../images/customer-insignts.webp";
import LessCommision from "../../../images/less-commision.webp";
import OldManRecievingPackage from "../../../images/old-man-accepting-order.webp";
import TimelyPayments from "../../../images/save-time-money.webp";

import { Message } from "../../../lib/getMessages";
import loadable from "@loadable/component";
import WithProviders from "../../../components/WithProviders";
const ContactForm = loadable(() => import("../../../components/ContactForm"));
function Faq({ expanded, question, answer, onToggle }) {
  const contentRef = useRef(null);

  const checkLinkClick = (e) => {
    if (e.target.tagName !== "A") {
      onToggle();
    }
  };

  const containerStyle = {
    maxHeight: expanded ? contentRef.current.offsetHeight + "px" : 0,
  };

  return (
    <div
      className={`faq ${expanded ? "expanded" : ""}`}
      onClick={checkLinkClick}
      onKeyDown={() => {}}
      tabIndex={0}
      role="button"
      aria-label="Click to expand"
    >
      <div className="question">
        <h4>{question}</h4>
        <div className={`${expanded ? "up-arrow" : "down-arrow"}`}></div>
      </div>
      <div className="content-container" style={containerStyle}>
        <div className="answer muted" ref={contentRef}>
          <div>{answer}</div>
        </div>
      </div>
    </div>
  );
}

const Ondc = (props) => {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const metaText =
    "Register as a seller on ONDC and sell your products across multiple buyer apps. Selling on ONDC made simple with Zopping. Contact us today!";
  const { language, updateLanguage, direction } = props;
  const toggleAccordion = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };
  const whyTrustONDC = [
    {
      icon: amblem,
      alt: "amblem",
      dictKey: "Backed.by.India",
    },
    {
      icon: sellers,
      alt: "sellers",
      dictKey: "Sellers.registered",
    },
    {
      icon: sku,
      alt: "sku",
      dictKey: "skus.sell",
    },
    {
      icon: city,
      alt: "citys",
      dictKey: "live.in.citys.expanding",
    },
  ];
  const whyUseZopping = [
    {
      icon: zoppingONDC,
      alt: "ONDC on zopping",
      dictKey: "Zopping.ondc",
    },
    {
      icon: zoppingApp,
      alt: "zopping App",
      dictKey: "Zopping.mobile.app",
    },
    {
      icon: zoppingMoney,
      alt: "Affordable zopping",
      dictKey: "Zopping.price",
    },
    {
      icon: zoppingSupport,
      alt: "zopping Support",
      dictKey: "Zopping.Support",
    },
  ];
  const whySellOnONDC = [
    {
      img: GirlsShopping,
      alt: "Girls shopping",
      dictKey: "Reach.a.bigger.audience",
      description: ["Reach.a.bigger.audience.description"],
    },
    {
      img: CustomersInsights,
      alt: "Customers Insights",
      dictKey: "Access.Real.time.Customer.Data",
      description: ["Access.Real.time.Customer.Data.description"],
    },
    {
      img: LessCommision,
      alt: "Pay less commision",
      dictKey: "Pay.less.commision",
      description: ["Pay.less.commision.description"],
    },
    {
      img: OldManRecievingPackage,
      alt: "Old Man Recieving Package",
      dictKey: "You.set.the.terms.for.your.business",
      description: ["You.set.the.terms.for.your.business.description"],
    },
    {
      img: TimelyPayments,
      alt: "Timely Payments",
      dictKey: "Receive.Timely.Payments",
      description: ["Receive.Timely.Payments.description"],
    },
  ];

  const ONDCFrequentlyAskedQuestions = [
    {
      question: <Message dictKey="ondc.faq.1" />,
      answer: (
        <p>
          <Message dictKey="ondc.faq.1.ans" />
        </p>
      ),
    },
    {
      question: <Message dictKey="ondc.faq.2" />,
      answer: (
        <div>
          <p>
            <Message dictKey="ondc.faq.2.ans" />
          </p>
        </div>
      ),
    },
    {
      question: <Message dictKey="ondc.faq.3" />,
      answer: (
        <p>
          <Message dictKey="ondc.faq.3.ans" />
        </p>
      ),
    },
  ];

  const faqs = () => {
    return ONDCFrequentlyAskedQuestions.map((faq, index) => (
      <Faq
        key={`faq-${index}`}
        onToggle={() => toggleAccordion(index)}
        expanded={expandedIndex === index}
        question={faq.question}
        answer={faq.answer}
      />
    ));
  };
  let canonicalUrl = `https://staging.zopping.com/solutions/ondc`;
  if (typeof window !== "undefined") {
    if (window.location.host === "zopping.com") {
      canonicalUrl = `https://zopping.com/solutions/ondc`;
    }
  }
  const isDirectionRTL = direction === "rtl";
  return (
    <Layout
      isDirectionRTL={isDirectionRTL}
      language={language}
      location={props.location}
    >
      <div className="eazy-page">
        <Helmet title="Zopping - ONDC">
          <meta property="og:image" content={ThumbnailImage} />
          <meta name="description" content={metaText} />
          <link rel="canonical" href={canonicalUrl} />
        </Helmet>
        <EazyHeader
          language={language}
          updateLanguage={updateLanguage}
          current="solutions/ondc"
          isDirectionRTL={isDirectionRTL}
        />
        <div className="eazy-solutions">
          <section className="overview-ondc">
            <div className="overview-heading">
              <h2>
                <span ondc-heading>
                  <Message dictKey="ONDC.full" />
                </span>
              </h2>
              <p>
                <span>
                  <Message dictKey="ONDC.description" />
                </span>
              </p>
              <div className="createbtn-wrp">
                <a href="#contactForm">
                  <button className="create-store-btn">
                    <Message dictKey="Join.ondc" />
                  </button>
                </a>

                <a href="/blog/introduction-to-ondc">
                  <button className="whatIsOndc-btn">
                    <Message dictKey="What.is.ondc" />
                  </button>
                </a>
              </div>
            </div>
          </section>
          <div className="hyperlocal-wrp">
            <section className="eazy-solutions-selling white-background">
              <h1>
                <span className="translation-span">
                  <Message dictKey="Why.Sell.on.ONDC" />
                </span>
              </h1>
              <hr className="desktop-border"></hr>
              <hr className="mobile-border-top"></hr>
            </section>
            <section className="eazy-solutions-selling">
              <section className="features wrap">
                {whySellOnONDC?.map((items, index) => {
                  return (
                    <div
                      className={
                        index % 2 === 0 ? "sell-things" : "sell-things-rev"
                      }
                    >
                      <section
                        className={
                          index % 2 === 0
                            ? "feature quality-website rev solutions"
                            : "feature unique-online-store solutions"
                        }
                      >
                        <div className="feature-media">
                          <img
                            src={items?.img}
                            alt={items?.alt}
                            width="580"
                            height="471"
                          />
                        </div>
                        <div className="feature-details">
                          <h2>
                            <Message dictKey={items?.dictKey} />
                          </h2>
                          {items?.description?.map((descrip) => {
                            return (
                              <p>
                                <span>
                                  <Message dictKey={descrip} />
                                </span>
                              </p>
                            );
                          })}
                        </div>
                      </section>
                    </div>
                  );
                })}
              </section>
            </section>
            <section className="eazy-solutions-selling">
              <h1>
                <span className="translation-span">
                  <Message dictKey="Why.trust.ONDC" />
                </span>
              </h1>
              <hr className="desktop-border"></hr>
              <hr className="mobile-border-top"></hr>
              <div className="icon-wrapper">
                {whyTrustONDC?.map((items, index) => {
                  return (
                    <div className="Img-wrapper" key={index}>
                      <div className="grocery-img-wrp">
                        <img
                          src={items?.icon}
                          alt={items?.alt}
                          className="grocery-img"
                        />
                      </div>
                      <h3>
                        <span className="icon-desc-text-wrp">
                          <Message dictKey={items?.dictKey} />
                        </span>
                      </h3>
                    </div>
                  );
                })}
              </div>
              <div className="ondc-footnote">
                <Message dictKey="Why.Trust.ONDC.FootNote" />
              </div>
            </section>
            <section className="eazy-solutions-selling white-background">
              <h1>
                <span className="translation-span">
                  <Message dictKey="Why.use.Zopping" />
                </span>
              </h1>
              <hr className="desktop-border"></hr>
              <hr className="mobile-border-top"></hr>
              <div className="why-zopping">
                <div className="icon-wrapper-why-zopping">
                  {whyUseZopping?.map((items, index) => {
                    return (
                      <div className="Img-wrapper-why-zopping" key={index}>
                        <div className="why-zopping-img-wrp">
                          <img
                            src={items?.icon}
                            alt={items?.alt}
                            className="why-zopping-img"
                          />
                        </div>
                        <h3>
                          <span className="icon-desc-text-wrp-why-zopping">
                            <Message dictKey={items?.dictKey} />
                          </span>
                        </h3>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
            <section
              className="eazy-solutions-selling white-background"
              id="contactForm"
            >
              <h1>
                <span className="translation-span">
                  <Message dictKey="How.join.ondc" />
                </span>
              </h1>
              <hr className="desktop-border"></hr>
              <hr className="mobile-border-top"></hr>
              <section className="Ondc-contact-form">
                <ContactForm ShowQuickResponse={false} />
              </section>
            </section>
            <section className="eazy-solutions-selling faq-section">
              <h1>
                <span className="translation-span">
                  <Message dictKey="ondc.faq" />
                </span>
              </h1>
              <hr className="desktop-border"></hr>
              <hr className="mobile-border-top"></hr>
              <section className="faqs">{faqs()}</section>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default WithProviders(Ondc);
